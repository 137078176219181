import React from "react";
import Logo from '../Images/Logo.webp'

import '../Styles/Extra/Loading.css'

const Loading = () => {

    return(

        <React.Fragment>
            <div className="Loading-Main">
                <img src={Logo} alt="LogoL"/>
            </div>
        </React.Fragment>
    )
}

export default Loading