import React, { useState } from "react";
import { db } from '../../Extra/Firebase';
import '../../Styles/Platform/GymInfo.css';
import { useParams, useNavigate } from "react-router-dom";
import Loading from "../../Extra/Loading";

const GymInfo = () => {
    const [gymName, setGymName] = useState('');
    const [gymLocation, setGymLocation] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const { Userid } = useParams();
    const nav = useNavigate();

    const handleGymNameChange = (event) => {
        setGymName(event.target.value);
    };

    const handleGymLocationChange = (event) => {
        setGymLocation(event.target.value);
    };

    const generateRandomGymId = () => {
        return Math.floor(100000000 + Math.random() * 900000000).toString();
    };

    const checkGymIdExists = async (GymId) => {
        const gymDoc = await db.collection('GymInfo').doc(GymId).get();
        return gymDoc.exists;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            let GymId = generateRandomGymId();
            while (await checkGymIdExists(GymId)) {
                GymId = generateRandomGymId();
            }

            const userDocRef = db.collection('GymInfo').doc(GymId);
            await userDocRef.set({
                gymInfo: {
                    gymName,
                    gymLocation
                }
            });
            setLoading(false);
            nav(`/GymInfo/${Userid}/Plans`);
        } catch (error) {
            setLoading(false);
            setError("Error updating gym information: " + error.message);
        }
    };

    if (loading) return <Loading />;

    return (
        <div className="GymInfoMain">
            <h1>Gym Information</h1>
            <form className="GymInfoForm" onSubmit={handleSubmit}>
                <label htmlFor="gymName">Gym Name:</label>
                <input
                    type="text"
                    id="gymName"
                    value={gymName}
                    onChange={handleGymNameChange}
                    required
                />
                <label htmlFor="gymLocation">Gym Location:</label>
                <input
                    type="text"
                    id="gymLocation"
                    value={gymLocation}
                    onChange={handleGymLocationChange}
                    required
                />
                <button type="submit" disabled={loading}>
                    {loading ? 'Saving...' : 'Save'}
                </button>
                {error && <p className="error-message">{error}</p>}
            </form>
        </div>
    );
};

export default GymInfo;
