import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from '../../Extra/Firebase';
import '../../Styles/Platform/Main.css';

import Loading from "../../Extra/Loading";

const Main = () => {
    const { Userid } = useParams();
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const userDoc = await getDoc(doc(db, "Users", Userid));
                if (userDoc.exists()) {
                    setUserData(userDoc.data());
                } else {
                    setError("User not found");
                }
            } catch (error) {
                setError("Error fetching user data: " + error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, [Userid]);

    useEffect(() => {
        // Prevent navigation using browser back button
        const handleBackButton = (event) => {
            event.preventDefault();
            // Replace with your desired route or action
        };

        window.history.pushState(null, "", window.location.href);
        window.onpopstate = handleBackButton;

        return () => {
            window.removeEventListener("popstate", handleBackButton);
        };
    }, []);

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <div className="UserData">
            <h1>User Details</h1>
            <p><strong>First Name:</strong> {userData.firstName}</p>
            <p><strong>Last Name:</strong> {userData.lastName}</p>
            <p><strong>Phone Number:</strong> {userData.phoneNumber}</p>
            <p><strong>Email:</strong> {userData.email}</p>
        </div>
    );
};

export default Main;
