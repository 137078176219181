import React, { useRef } from "react";
import '../Styles/Home/Navbar.css'
import { FaTimes, FaBars } from "react-icons/fa";
import Logo from '../Images/Logo.webp'
import Vec1 from '../Images/Vector1.jpg'
import { useNavigate } from "react-router-dom";

import Fade from 'react-reveal'

import '../Styles/Home/Sec-1.css'
import '../Styles/Home/Sec-2.css'

const Home = () => {

    const navRef = useRef()
    const nav = useNavigate()

    const showNavbar = () => {
        navRef.current.classList.toggle("responsive_nav");
    };

    return (
        <React.Fragment>

            <header>
              <img
                src={Logo}
                alt="header logo"
                onContextMenu={(e) => e.preventDefault()}
                onDragStart={(e) => e.preventDefault()}
              />
      
              <nav className="navbar" ref={navRef}>
                <div className="innav">
                  <img
                    src={Logo}
                    alt="header logo"
                    onContextMenu={(e) => e.preventDefault()}
                    onDragStart={(e) => e.preventDefault()}
                  />
                  <button onClick={showNavbar} className="navbtn navhide">
                    <FaTimes color="#03fca9" />
                  </button>
                </div>
                <button>Plans</button>
                <button>Services</button>
                <button>About us</button>
                <button onClick={() => {nav('/Login')}} className="LoginB">
                   Login
                </button>
              </nav>
              <button onClick={showNavbar} className="navbtn navshow">
                <FaBars color="#03fca9" />
              </button>
            </header>

            <section className="Sec-1">

                <Fade>

                <div className="Block1">
                    <h1>Let's make it better with us !</h1>

                    <div className="Sec-1-buttons">
                        <button onClick={() => {nav('/Login')}}>Login</button>
                        <button onClick={() => {nav('/Signup')}}>Sign up</button>
                    </div>

                    <div className="Sec-1-buttons2">
                        <button>View our Plans</button>
                    </div>
                </div>

                <div className="Block2">
                    <img src={Vec1} alt="I1"/>
                </div>

                </Fade>

            </section>

            <section className="Sec-2">

                <h1>Why us ?</h1>


                <div>
                    <p>Easy to use</p>
                    <p>Affordable price</p>
                    <p>Develop your customers relationships</p>
                    <p>Work tracking</p>
                    <p>Automated system for your gym</p>
                    <p>Payments (Links, reminders)</p>
                </div>

                <button>Our Plans</button>
            </section>

        </React.Fragment>
    )
}

export default Home