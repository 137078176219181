import React, { useState } from "react";
import { auth, db } from '../../Extra/Firebase';
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import '../../Styles/Auth/Signup.css';
import Logo from '../../Images/Logo.webp';

const Signup = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const navigate = useNavigate();

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value);
    };

    const handleLastNameChange = (event) => {
        setLastName(event.target.value);
    };

    const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };

    const handleSignup = async () => {
        if (password !== confirmPassword) {
            alert("Passwords do not match");
            return;
        }
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            await setDoc(doc(db, "Users", user.uid), {
                firstName,
                lastName,
                phoneNumber,
                email
            });

            navigate(`/GymInfo/${user.uid}`);
        } catch (error) {
            alert("Please check your info and try again");
        }
    };

    return (
        <React.Fragment>
            <div className="SignupMain">
                <img src={Logo} alt="Logo" />
                <h1>Signup</h1>
                <p>Create your account</p>
                <div className="SignupInputs">
                    <input type="text" placeholder="First Name" value={firstName} onChange={handleFirstNameChange} required />
                    <input type="text" placeholder="Last Name" value={lastName} onChange={handleLastNameChange} required />
                    <input type="number" placeholder="Phone Number" value={phoneNumber} onChange={handlePhoneNumberChange} required />
                    <input type="text" placeholder="Email" value={email} onChange={handleEmailChange} required />
                    <input type="password" placeholder="Password" value={password} onChange={handlePasswordChange} required />
                    <input type="password" placeholder="Confirm Password" value={confirmPassword} onChange={handleConfirmPasswordChange} required />
                    <button onClick={handleSignup}>Sign Up</button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Signup;
