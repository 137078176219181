import React, { useState, useEffect } from "react";
import { auth } from '../../Extra/Firebase';
import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import '../../Styles/Auth/Login.css';
import Logo from '../../Images/Logo.webp';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                navigate(`/Main/${user.uid}`);
            } else {
                console.log("Error");
            }
        });

        return () => unsubscribe();
    }, [navigate]);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleLogin = async () => {
        try {
            await signInWithEmailAndPassword(auth, email, password);
        } catch (error) {
            alert("Error logging in: Please check your email and password.");
        }
    };

    return (
        <React.Fragment>
            <div className="LoginMain">
                <img src={Logo} alt="Logo" />
                <h1>Login</h1>
                <p>Log in to your platform</p>
                <div className="LoginInputs">
                    <input type="text" placeholder="Email" value={email} onChange={handleEmailChange} />
                    <input type="password" placeholder="Password" value={password} onChange={handlePasswordChange} />
                    <button onClick={handleLogin}>Log In</button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Login;
