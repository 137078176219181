import React from "react";

const Plans = () => {

    return(

        <React.Fragment>

            Plans
        </React.Fragment>
    )
}

export default Plans