import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./Components/Home";
import Login from "./Components/Auth/Login";
import Signup from "./Components/Auth/Signup";

//Platform
import Main from "./Components/Platform/Main";
import Loading from "./Extra/Loading";
import GymInfo from "./Components/Platform/GymInfo";
import Plans from "./Components/Platform/Plans";

function App () { 

  return (
    <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/Login" element={<Login/>}/>
      <Route path="/Signup" element={<Signup/>}/>
      <Route path="/GymInfo/:Userid" element={<GymInfo/>}/>
      <Route path="/GymInfo/:Userid/Plans" element={<Plans/>}/>

      <Route path="/Main/:Userid" element={<Main/>}/>
      <Route path="/Loading" element={<Loading/>}/>
    </Routes>
  )
}

export default App